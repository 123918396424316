<template>
  <div class="results-block-wrap position-absolute"
       v-click-outside="outside"
       :class="{active: isOpened}">

    <template v-if="!loading && liveSearchResults?.length > 0">
      <div class="results-block">
        <div class="result-item add-item-block"
             v-for="result in liveSearchResults"
             @click="competitorClickHandler(result)">
          <div class="left-part">

            <div class="product-type">
              <svg-icon :icon="result?.store?.key === 'GOOGLE_PLAY' ? 'google-play' : 'app-store'"/>
            </div>

            <template v-if="result?.logo">
              <div class="product-logo">
                <app-image
                    :width="22"
                    :height="22"
                    alt="App image"
                    :lazy-src="result?.logo"
                />
              </div>
            </template>
            <template v-else>
              <div class="empty-logo"></div>
            </template>

            <div class="product-name">{{ result?.title ?? '' }}</div>
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="loading">
      <div class="loader-wrap">
        <preloader-table-small></preloader-table-small>
      </div>
    </template>
    <template v-else>
      <div class="no-results">We couldn't find what you are looking for</div>
    </template>
  </div>
</template>

<script>
import PreloaderTableSmall from '@/components/UI/PreloaderTableSmall/index';

export default {
  name: "ResultsList",
  components: {
    'preloader-table-small': PreloaderTableSmall,
  },
  props: {
    liveSearchResults: {
      type: Array
    },
    loading: {
      type: Boolean,
    }
  },
  emits: ['competitor-clicked'],
  data() {
    return {
      isOpened: false,
    }
  },
  mounted() {
    this.isOpened = this.liveSearchResults?.length > 0;
  },
  methods: {
    outside() {
      this.isOpened = false;
    },
    competitorClickHandler(app) {
      this.$emit('competitor-clicked', {
        id: app?.id,
        title: app?.title,
        store: app?.store?.key,
      });
    }
  },
  watch: {
    liveSearchResults(newVal, oldVal) {
      if (JSON.stringify(newVal) !== JSON.stringify(oldVal) && newVal?.length > 0) {
        this.isOpened = true;
      }
    },
    loading(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.isOpened = true;
      }
    },
  }
}
</script>

<style lang="scss" src="./styles.scss"></style>