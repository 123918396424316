<template>
  <the-new-header/>
  <div class="page-content analyze-app">
    <slot name="sidebar"></slot>
    <main :class="[{'collapsed': sidebarIsCollapsed}, {'full-width': isMobile}]">

      <div class="new-container">
        <h1 class="page-title">
          <template v-if="!showFirstScreen">{{ title }}</template>
        </h1>
      </div>

      <div class="analyze-app-content"
           :class="{'first-screen': showFirstScreen}">
        <div class="new-container">
          <template v-if="showFirstScreen">
            <div class="page-intro-search-block">
              <div class="block-slogan">
                Analyze any app now! Enter app name and uncover its performance:
              </div>
              <div class="search-input-block">
                <div class="search-input-wrap">
                  <form>
                    <label>
                      <input type="text"
                             class="search-input"
                             @input.prevent="inputSearchHandler"
                             @paste="pasteHandler"
                             @keypress.prevent.enter
                             placeholder="Search for apps"
                             ref="searchInput"
                             autofocus
                             v-model="currentKeyword">
                    </label>
                  </form>
                </div>
                <results-list :live-search-results="liveSearchResults"
                              :loading="searchIsLoading"
                              @competitor-clicked="competitorsItemClickHandler"/>
              </div>

              <template v-if="competitorsLoading">
                <div class="preloader-wrap">
                  <preloader-table-small loader-size="20px"></preloader-table-small>
                </div>
              </template>
              <template v-else>
                <template v-if="competitorsArray?.length > 0">
                  <div class="recommended-keywords-block">
                    <div class="block-subtitle">Or choose from competitors:</div>
                    <div class="recommended-competitors-block">
                      <div v-for="item in competitorsArray"
                           class="competitor-item-wrap"
                           @click="competitorsItemClickHandler(item)">
                        <div class="competitor-item">
                          <app-image
                                v-if="item?.logo"
                                :width="50"
                                :height="50"
                                :lazy-src="cropImg(item?.logo, '50x50', item?.store)"
                          />
                          <template v-else>
                            <div class="empty-logo"></div>
                          </template>
                          <div class="tooltip"
                               :style="{width: calculateTooltipWidth(item?.title)}">
                            {{ item?.title }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </template>
            </div>
          </template>
          <template v-else>
            <div class="search-input-block app-analyze-input">
              <div class="search-input-wrap">
                <form>
                  <label>
                    <input type="text"
                           class="search-input"
                           @input.prevent="inputSearchHandler"
                           @paste="pasteHandler"
                           @keypress.prevent.enter
                           placeholder="Search for apps"
                           ref="searchInput"
                           autofocus
                           v-model="currentKeyword">
                  </label>
                </form>
              </div>
              <results-list :live-search-results="liveSearchResults"
                            :loading="searchIsLoading"
                            @competitor-clicked="competitorsItemClickHandler"/>
              <order-radaso-button class="order-radaso-wrap" :pre-selected="['aso-audit']" />
            </div>

            <app-details-component v-if="loaded"
                                   :app-data="clickedItem"
                                   @competitor-changed="modalCompetitorChanged"
                                   :update-explore-component="updateExploreComponent"
                                   :change-competitor-in="true"/>
          </template>
        </div>
      </div>
    </main>

  </div>
</template>
<script>
import {httpRequest} from "@/api";
import {mapGetters} from "vuex";
import CustomChips from "@/components/Forms/CustomChips";
import KeywordExplorerComponent from "@/components/KeywordExplorerComponent/index.vue";
import {cropImage, debounce} from "@/utils/utils";
import ResultsList from "@/views/Analyze/AnalyzeApp/components/ResultsList/index.vue";
import AppDetailsComponent from "@/components/AppDetailsComponent/index.vue";
import DetailsModal from "@/components/AppDetailsComponent/DetailsModal/index.vue";
import PreloaderTableSmall from "@/components/UI/PreloaderTableSmall/index.vue";
import OrderRadasoButton from "@/components/OrderRadaso/Button/index.vue";

export default {
  name: "AnalyzeApp",
  components: {
    OrderRadasoButton,
    'custom-chips': CustomChips,
    'keyword-explorer-component': KeywordExplorerComponent,
    ResultsList,
    AppDetailsComponent,
    DetailsModal,
    PreloaderTableSmall,
  },
  data() {
    return {
      title: '',
      loaderText: '',
      showFirstScreen: true,
      currentKeyword: '',
      debouncedInput: '',
      showAppDetailsModal: false,
      searchIsLoading: false,
      clickedItem: {},
      competitorsArray: [],
      liveSearchResults: [],
      loaded: false,
      updateExploreComponent: 0,
      competitorsLoading: true,
      initialSearchRequest: true,
    }
  },
  async mounted() {
    this.searchIsLoading = false;
    this.liveSearchResults = [];
    this.loaded = true;
    if (window.location.search !== '' || window.history.state?.title !== '') {
      let queryString = window.location.search.charAt(0).replace('?', '&') + window.location.search.slice(1);

      let tempObj = {};

      if (window.location.search !== '') {
        const query = queryString.split('&');
        const urlPairs = query?.filter(item => item !== '');

        for (const idx in urlPairs) {
          const key = urlPairs[idx].split('=')[0];
          tempObj[key] = encodeURIComponent(urlPairs[idx].split('=')[1]);
        }

        if (tempObj?.id) {
          tempObj.id = +tempObj.id;
        }
      } else {
        tempObj = {
          title: window.history.state?.title,
          id: window.history.state?.id,
          store: window.history.state?.store,
        }
      }

      this.clickedItem = {...tempObj};
      const decodedAppTitle = decodeURIComponent(decodeURIComponent('' + tempObj?.title));

      if (decodedAppTitle && decodedAppTitle !== '' && decodedAppTitle !== 'undefined') {
        this.currentKeyword = decodedAppTitle;
      }

      if (JSON.stringify(this.clickedItem) !== '{}') {
        this.showFirstScreen = false;

        if (history.state?.title) {
          let urlString = '?title=' + history.state?.title + '&id=' + history.state?.id + '&store=' + history.state?.store;
          window.history.replaceState({}, '', urlString);
        }
      }
    }

    if (this.showFirstScreen) {
      await this.fetchCompetitorsData();
    }

    this.setDocumentTitle();
  },
  methods: {
    async inputSearchHandler() {
      this.initialSearchRequest = true;
    },
    async competitorsItemClickHandler(item) {
      this.clickedItem = {
        id: item?.id,
        title: item?.title,
        store: item?.store_key,
      };

      this.searchIsLoading = false;
      this.liveSearchResults = [];
      this.showAppDetailsModal = true;

      this.clickedItem = {
        id: item?.id,
        title: item?.title,
        store: item?.store,
      };

      this.currentKeyword = item?.title;
      this.initialSearchRequest = false;

      this.replaceUrl();
      let queryString = window.location.search.charAt(0).replace('?', '') + window.location.search.slice(1);
      const keyword = queryString.split('=')[1] ?? null;

      if (keyword) {
        this.showFirstScreen = false;
        this.updateExploreComponent++;
      }
    },
    async fetchLiveSearchResults() {
      this.searchIsLoading = true;

      const currentCountryCode = this.currentCountry?.code;
      const url = process.env.VUE_APP_API_URL + this.$ApiUrls.liveSearch.KEYWORDS_TOP + '?country_code=' + currentCountryCode
          + '&device=' + (this.currentApp?.store?.key === 'APP_STORE' ? 'iphone' : 'mobile')
          + '&keyword=' + this.debouncedInput
          + '&size=' + 15
          + '&skip=' + 0
          + '&apps_only=true'
          + '&app_id=' + this.currentApp?.id;

      const result = await httpRequest('GET', url);

      if (result?.list && result?.list?.length > 0) {
        this.liveSearchResults = result?.list.map((item, idx) => {
          const categories = item?.data?.categories?.join(', ');
          return {
            type: item?.type,
            ...item?.data,
            rating: item?.data?.rating ? item?.data.rating.toFixed(1) : 0,
            categories: categories,
            store: {
              key: item?.data?.store_key,
            },
          };
        });
      }

      this.searchIsLoading = false;
    },
    detailsModalClosed() {
      this.showAppDetailsModal = false;
      this.currentKeyword = '';
    },
    modalCompetitorChanged() {

    },
    cropImg(imageUrl, size, store) {
      return cropImage(imageUrl, size, store);
    },
    async fetchCompetitorsData(appId) {
      this.competitorsLoading = true;
      const currentAppId = appId ?? this.currentApp?.id;
      const competitorsUrl = '?app_id=' + currentAppId;
      const competitorsData = await httpRequest('GET', process.env.VUE_APP_API_URL + this.$ApiUrls.keywordsTracking.CUSTOM_COMPETITORS + competitorsUrl + '&is_competitor=1');

      if (competitorsData?.list?.isArray) {
        this.competitorsArray = competitorsData?.list?.map(item => {
          return {
            id: +item?.id,
            logo: item?.logo,
            title: item?.title,
            store: item?.store,
          }
        });

      } else if (Object?.values(competitorsData?.list)) {
        this.competitorsArray = Object.values(competitorsData?.list)?.map(item => {
          return {
            id: item?.id,
            logo: item?.logo,
            title: item?.title,
            store: item?.store,
          }
        });
      } else {
        this.competitorsArray = [];
      }

      this.competitorsLoading = false;
    },
    replaceUrl() {
      if (window.history.replaceState) {
        let queryString = '?title=' + this.clickedItem?.title + '&id=' + this.clickedItem?.id + '&store=' + this.clickedItem?.store;
        let stateObj = {
          title: encodeURIComponent(this.clickedItem?.title),
          id: this.clickedItem?.id,
          store: this.clickedItem?.store,
        };
        window.history.replaceState(stateObj, '', queryString);
      }
    },
    calculateTooltipWidth(string, maxWidth = 120) {
      const strLength = string?.length;
      if ((strLength * 10) > maxWidth) {
        return '120px';
      }
      return 'auto';
    },
    async pasteHandler(e) {
      this.debouncedInput = e.clipboardData.getData('text')
      await this.fetchLiveSearchResults();
    },
    setDocumentTitle() {
      let title = this.$route.meta.pageTitle;
      if (this.currentKeyword) {
        title = this.currentKeyword + ' Analysis: Store Insights, Metrics, Performance, etc';
      } else if (this.currentApp?.store?.key) {
        title += ' ' + this.storeTitle;
      }
      this.setPageTitle(title);
    },
  },
  computed: {
    ...mapGetters([
      'isMobile',
      'currentApp',
      'currentCountry',
      'currentCountryManual',
      'promiseSettledData',
      'sidebarIsCollapsed',
    ]),
    storeTitle() {
      return this.currentApp?.store?.key === "APP_STORE" ? "App store" : "Google Play";
    }
  },
  watch: {
    currentKeyword: debounce(async function (newVal, oldVal) {
      if (newVal !== '' && oldVal !== '' && this.initialSearchRequest) {
        this.debouncedInput = newVal;
        await this.fetchLiveSearchResults();
      }
      this.setDocumentTitle();
    }, 500),
    async currentApp(newVal, oldVal) {
      if (newVal?.id !== oldVal?.id && oldVal?.id) {
        this.loaded = false;

        if (this.showFirstScreen) {
          await this.fetchCompetitorsData(newVal?.id);
        }
        this.loaded = true;

        this.setDocumentTitle();
      }
    },
    async currentCountryManual(newVal, oldVal) {
      if (newVal?.code !== oldVal?.code) {
        this.loaded = false;

        if (this.showFirstScreen) {
          await this.fetchCompetitorsData(newVal?.id);
        }
        this.loaded = true;
      }
    },
  }
}
</script>

<style lang="scss" src="./styles.scss"></style>